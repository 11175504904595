<template>
    <div
        class="kt-portlet__body kt-block-center kt-align-center"
        id="emptylist_widget"
        v-if="isDisplayed == true"
    >
        <div class="kt-widget">
            <div class="kt-widget__head">
                <div class="kt-widget__media">
                    <img class="kt-widget__img kt-hidden-" :src="imgSrc" alt="image" :class="[targetObjectName == 'WIDGET_PEOPLE_FLOW' ? 'width50' : 'width100']" />
                </div>
                <p class="noResourceTopLabel">{{ $t(this.mainTextKey) }}</p>
                <p class="noResourceSubLabel">
                    {{ $t(this.subTextKey) }}

                    <strong>
                        <a href="#"
                           @click="onEditWidget"
                           v-if="targetObjectName == 'WIDGET_PEOPLE_FLOW'">
                            {{ $t("analytics_goto_settings") }}>
                        </a>
                    </strong>
                </p>

                <router-link
                    id="emptyList_createNewBuildingButton"
                    v-if="targetObjectName == 'BUILDING'"
                    :to="{ name: 'newBuilding' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("b&f_createNewBuilding") }}
                </router-link>

                <router-link
                    id="emptyList_createNewFloorButton"
                    v-if="targetObjectName == 'FLOOR'"
                    :to="{ name: 'newFloor' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("b&f_createNewFloor") }}
                </router-link>

                <div v-if="targetObjectName == 'GATEWAY'">
                    <div class="dropdown displayInlineBlock">
                        <button
                            class="btn kt-margin-t-5 kt-margin-b-20 btn-bold btn-success btn-sm dropdown-toggle"
                            type="button"
                            id="emptyList_importGatewaysDropDown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                            {{ $t("common_importFrom") }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style>
                            <a
                                class="dropdown-item"
                                id="emptyList_importGatewaysFromXLSButton"
                                @click="openImportGatewaysModal"
                                href="#"
                            >
                                <i class="la la-file-excel-o"></i>
                                {{ $t("gtw_fromLocalTemplateFile") }}
                            </a>
                            <a
                                class="dropdown-item"
                                id="emptyList_importGatewaysFromXLSOVButton"
                                @click="openImportGatewaysOVXlsModal"
                                href="#"
                            >
                                <img src="/assets/ale/ov.png" class="ovIcon" />
                                {{ $t("gtw_fromOmnivistaFile") }}
                            </a>
                        </div>
                    </div>
                    <div class="kt-margin-l-10 kt-margin-r-10 orLabel">{{ $t("common_or") }}</div>
                    <router-link
                        id="emptyList_createNewGatewayButton"
                        :to="{ name: 'newGateway' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("gtw_createNewBleGateway") }}
                    </router-link>
                </div>

                <div v-if="targetObjectName == 'TAG'">
                    <button
                        @click="openImportTagsModal"
                        id="emptyList_importTagsFromXLSButton"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-success btn-bold btn-sm"
                    >
                        <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                        {{ $t("common_importFromXLSFile") }}
                    </button>
                    <div class="kt-margin-l-10 kt-margin-r-10 orLabel">{{ $t("common_or") }}</div>
                    <router-link
                        id="emptyList_createNewTagButton"
                        :to="{ name: 'newTag' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("tag_createNewTag") }}
                    </router-link>
                </div>

                <div v-if="targetObjectName == 'TAG_MODEL'">
                    <router-link
                        id="emptyList_createNewTagModelButton"
                        :to="{ name: 'newTagModel' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("tag_createNewTagModel") }}
                    </router-link>
                </div>

                <div v-if="targetObjectName == 'AUTOCALIBRATION_TAG'">
                    <button
                        @click="openImportAutocalibrationTagsModal"
                        id="emptyList_importAutocalibrationTagsFromXLSButton"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-success btn-bold btn-sm"
                    >
                        <i class="fa fa-file-excel kt-margin-t-1 kt-margin-r-5"></i>
                        {{ $t("common_importFromXLSFile") }}
                    </button>
                    <div class="kt-margin-l-10 kt-margin-r-10 orLabel">{{ $t("common_or") }}</div>
                    <router-link
                        id="emptyList_createNewAutocalibrationTagButton"
                        v-if="targetObjectName == 'AUTOCALIBRATION_TAG'"
                        :to="{ name: 'newAutoCalibrationTag' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("act_createNewAutoCalibrationTag") }}
                    </router-link>
                </div>

                <template
                    v-if="targetObjectName == 'INDOOR_AREA' && currentIndoorArea.state === ''"
                >
                    <button
                        v-if="!siteInProduction"
                        id="emptyList_createNewIndoorAreaButton"
                        @click="createIndoorArea"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("area_createNewIndoorArea") }}
                    </button>
                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm">
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("area_createNewIndoorArea") }}
                    </button>
                </template>

                <template
                    v-if="targetObjectName == 'INDOOR_AREA' && currentIndoorArea.state !== ''"
                >
                    <button
                        id="emptyList_cancelNewIndoorAreaButton"
                        @click="cancelCreateIndoorArea"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-sm btn-label-dark btn-bold"
                    >{{ $t("common_cancel") }}</button>
                </template>

                <template v-if="targetObjectName == 'GEOFENCE' && currentGeofence.state === ''">
                    <button
                        id="emptyList_createNewGeofenceButton"
                        @click="createGeofence"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("geofence_createNewGeofence") }}
                    </button>
                </template>

                <template v-if="targetObjectName == 'GEOFENCE' && currentGeofence.state !== ''">
                    <button
                        id="emptyList_cancelNewGeofenceButton"
                        @click="cancelCreateGeofence"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-sm btn-label-dark btn-bold"
                    >{{ $t("common_cancel") }}</button>
                </template>

                <template v-if="targetObjectName == 'WALKWAY' && currentWalkway.state === ''">
                    <button
                        v-if="!siteInProduction"
                        id="emptyList_createNewWalkwayButton"
                        @click="createWalkway"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("walkway_createNewWalkway") }}
                    </button>
                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm">
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("walkway_createNewWalkway") }}
                    </button>
                </template>

                <template v-if="targetObjectName == 'WALKWAY' && currentWalkway.state !== ''">
                    <button
                        id="emptyList_cancelNewWalkwayButton"
                        @click="cancelCreateWalkway"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-sm btn-label-dark btn-bold"
                    >{{ $t("common_cancel") }}</button>
                </template>

                <template v-if="targetObjectName == 'WALL' && currentWall.state === ''">
                    <button
                        v-if="!siteInProduction"
                        id="emptyList_createNewWallButton"
                        @click="createWall"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("wall_createNewWall") }}
                    </button>
                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm">
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("wall_createNewWall") }}
                    </button>
                </template>

                <template v-if="targetObjectName == 'WALL' && currentWall.state !== ''">
                    <button
                        id="emptyList_cancelNewWallButton"
                        @click="cancelCreateWall"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-sm btn-label-dark btn-bold"
                    >{{ $t("common_cancel") }}</button>
                </template>

                <router-link
                    id="emptyList_createNewSiteButton"
                    v-if="targetObjectName == 'SITE'"
                    :to="{ name: 'newsite' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("site_createNewSite") }}
                </router-link>

                <router-link
                    id="emptyList_createNewAssetButton"
                    v-if="targetObjectName == 'ASSET'"
                    :to="{ name: 'newAsset' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("asset_createNewAsset") }}
                </router-link>

                <router-link
                    id="emptyList_createNewCustomAssetCategoryButton"
                    v-if="targetObjectName == 'CUSTOM_ASSET_CATEGORIES'"
                    :to="{ name: 'newAssetCategory' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("asset_createNewCategory") }}
                </router-link>

                <router-link
                    id="emptyList_createNewDashboardButton"
                    v-if="targetObjectName == 'DASHBOARD'"
                    :to="{ name: 'newDashboard' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("analytics_createNewDashboard") }}
                </router-link>

                <router-link
                    id="emptyList_createNewNotificationButton"
                    v-if="targetObjectName == 'NOTIFICATION'"
                    :to="{ name: 'newGeonotification' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("geo_notif_createNewNotification") }}
                </router-link>

                <router-link
                    id="emptyList_createNewPushButtonAlertButton"
                    v-if="targetObjectName == 'PUSHBUTTON_ALERT'"
                    :to="{ name: 'newPushButtonAlert' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("alert_createNewPushButtonAlert") }}
                </router-link>

                <router-link
                    id="emptyList_createNewPushButtonAlertButton"
                    v-if="targetObjectName == 'PUSHBUTTON_EVENT_TYPE'"
                    :to="{ name: 'newPushButtonEventType' }"
                    class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                >
                    <i class="flaticon2-plus kt-margin-r-5"></i>
                    {{ $t("alert_createNewPushButtonEventType") }}
                </router-link>

                <div v-if="targetObjectName == 'REPORT'">
                    <router-link
                        id="emptyList_createNewReportButton"
                        :to="{ name: 'newReport' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="flaticon2-plus kt-margin-r-5"></i>
                        {{ $t("reporting_createReport") }}
                    </router-link>
                    <div class="kt-margin-l-10 kt-margin-r-10 orLabel">{{ $t("common_or") }}</div>
                    <router-link
                        id="emptyList_generateReportButton"
                        :to="{ name: 'generateReport' }"
                        class="kt-margin-t-5 kt-margin-b-20 btn btn-bold btn-brand btn-sm"
                    >
                        <i class="la la-paper-plane kt-margin-r-5" style="font-size: 1.4rem !important;"></i>
                        {{ $t("reporting_sendAllReports") }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    name: "emptylist",
    props: {
        isDisplayed: Boolean,
        mainTextKey: String,
        subTextKey: String,
        imgName: String,
        targetObjectName: String,
        widgetId: String,
        siteInProduction: Boolean
    },
    data() {
        console.log("Component(EmptyList)::data() - called");
        return {
            imgSrc: "/assets/nodata/" + this.imgName
        };
    },
    created: function() {
        console.log("Component(EmptyList)::created() - called");
    },
    mounted: function() {
        console.log("Component(EmptyList)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(EmptyList)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentIndoorArea",
            "currentGeofence",
            "currentWalkway",
            "currentWall",
            "currentPushButtonAlert"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "showImportGatewaysModal",
            "showImportTagsModal",
            "showImportAutocalibrationTagsModal",
            "showImportUsersModal",
            "showImportGatewaysFromOVModal",
            "setWidgetToEdit"
        ]),

        onEditWidget: function() {
            this.setWidgetToEdit(this.widgetId);
        },

        openImportGatewaysModal() {
            // Reset data in modal
            this.$parent.$refs.importGatewaysModal.errorDisplayed = false;
            this.$parent.$refs.importGatewaysModal.xlsData = null;
            this.$parent.$refs.importGatewaysModal.isImportButtonDisabled = true;
            this.$parent.$refs.importGatewaysModal.xlsErrors = null;
            this.$parent.$refs.importGatewaysModal.xlsFile = null;
            this.$parent.$refs.importGatewaysModal.isXlsFileIsEmpty = false;
            this.$parent.$refs.importGatewaysModal.isSheetNotFound = false;
            //Force input file empty
            $("#importGatewaysInputFile").val("");
            // Reset placeholder of label
            $("#importGatewaysInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportGatewaysModal();
        },

        openImportGatewaysOVXlsModal() {
            // Reset data in modal
            this.$parent.$refs.importGatewaysFromOVModal.errorDisplayed = false;
            this.$parent.$refs.importGatewaysFromOVModal.csvData = null;
            this.$parent.$refs.importGatewaysFromOVModal.isImportButtonDisabled = true;
            this.$parent.$refs.importGatewaysFromOVModal.csvErrors = null;
            this.$parent.$refs.importGatewaysFromOVModal.csvFile = null;
            this.$parent.$refs.importGatewaysFromOVModal.isCSVFileIsEmpty = false;
            this.$parent.$refs.importGatewaysFromOVModal.isSheetNotFound = false;
            // Force input file empty
            $("#importGatewaysFromOVInputFile").val("");
            // Reset placeholder of label
            $("#importGatewaysFromOVInputLabel").text(
                i18n.t("common_chooseFile")
            );
            // Open modal
            this.showImportGatewaysFromOVModal();
        },

        openImportUsersModal() {
            // Reset data in modal
            this.$parent.$refs.importUsersModal.errorDisplayed = false;
            this.$parent.$refs.importUsersModal.xlsData = null;
            this.$parent.$refs.importUsersModal.isImportButtonDisabled = true;
            this.$parent.$refs.importUsersModal.xlsErrors = null;
            this.$parent.$refs.importUsersModal.xlsFile = null;
            this.$parent.$refs.importUsersModal.isXlsFileIsEmpty = false;
            this.$parent.$refs.importUsersModal.isSheetNotFound = false;
            // Force input file empty
            $("#importUsersInputFile").val("");
            // Reset placeholder of label
            $("#importUsersInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportUsersModal();
        },

        openImportTagsModal() {
            // Reset data in modal
            this.$parent.$refs.importTagsModal.errorDisplayed = false;
            this.$parent.$refs.importTagsModal.xlsData = null;
            this.$parent.$refs.importTagsModal.isImportButtonDisabled = true;
            this.$parent.$refs.importTagsModal.xlsErrors = null;
            this.$parent.$refs.importTagsModal.xlsFile = null;
            this.$parent.$refs.importTagsModal.isXlsFileIsEmpty = false;
            this.$parent.$refs.importTagsModal.isSheetNotFound = false;
            //Force input file empty
            $("#importTagsInputFile").val("");
            // Reset placeholder of label
            $("#importTagsInputLabel").text(i18n.t("common_chooseFile"));
            // Open modal
            this.showImportTagsModal();
        },

        openImportAutocalibrationTagsModal() {
            // Reset data in modal
            this.$parent.$refs.importAutocalibrationTagsModal.errorDisplayed = false;
            this.$parent.$refs.importAutocalibrationTagsModal.xlsData = null;
            this.$parent.$refs.importAutocalibrationTagsModal.isImportButtonDisabled = true;
            this.$parent.$refs.importAutocalibrationTagsModal.xlsErrors = null;
            this.$parent.$refs.importAutocalibrationTagsModal.xlsFile = null;
            this.$parent.$refs.importAutocalibrationTagsModal.isXlsFileIsEmpty = false;
            this.$parent.$refs.importAutocalibrationTagsModal.isSheetNotFound = false;
            //Force input file empty
            $("#importAutocalibrationTagsInputFile").val("");
            // Reset placeholder of label
            $("#importAutocalibrationTagsInputLabel").text(
                i18n.t("common_chooseFile")
            );
            // Open modal
            this.showImportAutocalibrationTagsModal();
        },

        createIndoorArea() {
            // event caught by parent component (indoorareas.vue)
            this.$emit("oncreateindoorarea");
        },

        cancelCreateIndoorArea() {
            // event caught by parent component (indoorareas.vue)
            this.$emit("oncancelcreateindoorarea");
        },

        createGeofence() {
            // event caught by parent component (geofences.vue)
            this.$emit("oncreategeofence");
        },

        cancelCreateGeofence() {
            // event caught by parent component (geofences.vue)
            this.$emit("oncancelcreategeofence");
        },

        createGeonotification() {
            // event caught by parent component (geonotifications.vue)
            this.$emit("oncreatenotification");
        },

        cancelCreateNotification() {
            // event caught by parent component (geonotifications.vue)
            this.$emit("oncancelcreatenotification");
        },

        createWalkway() {
            // event caught by parent component (walkways.vue)
            this.$emit("oncreatewalkway");
        },

        cancelCreateWalkway() {
            // event caught by parent component (walkways.vue)
            this.$emit("oncancelcreatewalkway");
        },

        createWall() {
            // event caught by parent component (walls.vue)
            this.$emit("oncreatewall");
        },

        cancelCreateWall() {
            // event caught by parent component (walls.vue)
            this.$emit("oncancelcreatewall");
        },

        createPushButtonAlert() {
            // event caught by parent component (pushbuttonalerts.vue)
            this.$emit("oncreatepushbuttonalert");
        },

        cancelCreatePushButtonAlert() {
            // event caught by parent component (pushbuttonalerts.vue)
            this.$emit("oncancelcreatepushbuttonalert");
        },

        createPushButtonEventType() {
            // event caught by parent component (pushbuttoneventtypes.vue)
            this.$emit("oncreatepushbuttonpushbuttoneventtype");
        },

        cancelCreatePushButtonEventType() {
            // event caught by parent component (pushbuttoneventtypes.vue)
            this.$emit("oncancelcreatepushbuttoneventtype");
        }


    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}

.fullWidth img {
    max-width: 100%;
    height: auto;
}
.width100 {
    width: 100px;
}
.width50 {
    width: 50px;
}
.noResourceTopLabel {
    margin-top: 25px;
    font-weight: 400;
    font-size: 15px;
    color: black;
}
.noResourceSubLabel {
    font-weight: 400;
    font-size: 12px;
}
.orLabel {
    display: inline-block;
    position: relative;
    top: -6px;
}
</style>
